<template>
	<div data-component="additional-info-list-row">
		<p
			v-if="getHeading"
			data-element="heading"
		>
			{{ getHeading }}
		</p>
		<p
			v-if="getText"
			data-element="text"
		>
			{{ getText }}
		</p>
		<div
			v-if="getBadges"
			data-element="badges"
		>
			<badge
				v-for="(badge, key) in getBadges"
				:key="key"
				:text="badge"
				colour="grey-lighter-1"
			/>
		</div>
	</div>
</template>

<script>

	import Badge from '@/components/ui/Badge';

	export default {
		components: {
			Badge
		},
		props: {
			row: {
				type: Object,
				default: undefined
			}
		},
		computed: {
			getHeading () {
				return this.row?.heading;
			},
			getText () {
				return this.row?.text;
			},
			getBadges () {
				return this.row?.badges;
			}
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='additional-info-list-row'] {
		padding:rem(16) 0;
		border-bottom:3px solid $c-brand-grey-lighter-5;
		[data-element='heading'] {
			font-size:rem(18);
			color:$c-brand-blue;
			margin-bottom:rem(8);
		}
		[data-element='text'] {
			font-family:'Montserrat', sans-serif;
			font-size:rem(18);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-element='badges'] {
			margin:rem(12) 0 0;
			display:flex;
			flex-wrap:wrap;
			[data-component='badge'] {
				margin:0 rem(12) rem(8) 0;
			}
		}
	}
</style>
