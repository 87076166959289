<template>
	<div
		data-component="course-action-groups"
		v-if="getShowGroups"
	>
		<action-groups
			:groups="getGroups"
			@actionClick="onActionClick"
		/>
		<portal
			to="modal"
			v-if="editApprovalNoteIsVisible"
		>
			<modal-inner
				@close="toggleEditApprovalNote"
				heading="Admin approval note"
			>
				<validation-observer
					data-element="form"
					tag="form"
					ref="form"
				>
					<input-field
						name="Note"
						type="text"
						placeholder="Add a note for the teacher"
						v-model="course.approvalNote"
					/>
					<actions
						:actions="getApprovalNoteActions"
						@actionClick="onActionClick"
					/>
				</validation-observer>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="viewApprovalNoteIsVisible"
		>
			<modal-inner
				@close="toggleViewApprovalNote"
				heading="Admin approval note"
			>
				<div
					data-element="approval-note"
					v-html="course.approvalNote"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="unpublishCourseConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleUnpublishCourseConfirmation"
				heading="Unpublish course"
			>
				<confirmation-modal
					text="Unpublishing the course will hide the course from all students."
					confirmationText="Unpublish"
					confirmationActionId="unpublishCourse"
					cancelActionId="toggleUnpublishCourseConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="archiveCourseConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleArchiveCourseConfirmation"
				heading="Archive course"
			>
				<confirmation-modal
					text="Archiving the course will hide the course from new students."
					confirmationText="Archive"
					confirmationActionId="archiveCourse"
					cancelActionId="toggleArchiveCourseConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="deleteCourseConfirmationIsVisible"
		>
			<modal-inner
				@close="toggleDeleteCourseConfirmation"
				heading="Delete course"
			>
				<confirmation-modal
					text="Deleting the course will completely remove it from a teachers list of courses."
					confirmationText="Delete"
					confirmationActionId="deleteCourse"
					cancelActionId="toggleDeleteCourseConfirmation"
					@actionClick="onActionClick"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import ActionGroups       from '@/components/ui/actionGroups/ActionGroups';
	import ModalInner         from '@/components/ui/modal/ModalInner';
	import InputField         from '@/components/forms/InputField';
	import Actions            from '@/components/ui/Actions';
	import ConfirmationModal  from '@/components/ui/modal/ConfirmationModal';
	import actionClick        from '@/mixins/actionClick';
	import routeParams        from '@/mixins/routeParams';
	import api                from '@/services/api';

	export default {
		components: {
			ActionGroups,
			ModalInner,
			InputField,
			Actions,
			ConfirmationModal
		},
		mixins: [actionClick, routeParams],
		props: {
			course: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			editApprovalNoteIsVisible: false,
			viewApprovalNoteIsVisible: false,
			unpublishCourseConfirmationIsVisible: false,
			archiveCourseConfirmationIsVisible: false,
			deleteCourseConfirmationIsVisible: false
		}),
		computed: {
			getShowGroups () {
				return this.getGroups.filter((group) => {
					return group.rows.length > 0;
				}).length > 0;
			},
			getUserHasNotificationsEnabled () {
				return this.course?.notifications;
			},
			getShowEnableNotifications () {
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return !this.getUserHasNotificationsEnabled;
			},
			getShowDisableNotifications () {
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.getUserHasNotificationsEnabled;
			},
			getShowLinkStripe () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				return !this.$store.getters['auth/getCanPublishCourses'];
			},
			getShowRequestPublication () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (this.course?.approval === 'pending') {
					return false;
				}
				if (this.course?.approval === 'approved') {
					return false;
				}
				if (!this.$store.getters['auth/getCanPublishCourses']) {
					return false;
				}
				if (this.course?.status !== 'draft') {
					return false;
				}
				return true;
			},
			getShowRequestedPublication () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (this.course?.approval !== 'pending') {
					return false;
				}
				return true;
			},
			getShowApprovedForPublication () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (this.course?.approval !== 'approved') {
					return false;
				}
				return true;
			},
			getShowRejectedForPublication () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (this.course?.approval !== 'rejected') {
					return false;
				}
				return true;
			},
			getShowReview () {
				if (this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval !== 'pending') {
					return false;
				}
				return true;
			},
			getShowReviewedAndApproved () {
				if (this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval !== 'approved') {
					return false;
				}
				return true;
			},
			getShowReviewedAndRejected () {
				if (this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval !== 'rejected') {
					return false;
				}
				return true;
			},
			getApprovalNoteActions () {
				return {
					primary: [
						{
							text: 'Approve',
							actionId: 'approveCourse'
						},
						{
							text: 'Reject',
							actionId: 'rejectCourse'
						}
					]
				};
			},
			getShowPublish () {
				if (this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval === 'pending') {
					return false;
				}
				if (this.course?.status !== 'draft') {
					return false;
				}
				return true;
			},
			getShowUnpublish () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval === 'pending') {
					return false;
				}
				if (this.course?.status !== 'published') {
					return false;
				}
				return true;
			},
			getShowRepublish () {
				if (this.getContext !== 'teaching') {
					return false;
				}
				if (this.course?.approval !== 'approved') {
					return false;
				}
				if (this.course?.status !== 'draft') {
					return false;
				}
				return true;
			},
			getShowArchive () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval === 'pending') {
					return false;
				}
				if (this.course?.status !== 'published') {
					return false;
				}
				return true;
			},
			getShowUnarchive () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.status !== 'archived') {
					return false;
				}
				return true;
			},
			getShowDelete () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.approval === 'pending') {
					return false;
				}
				if (this.course?.status !== 'draft') {
					return false;
				}
				return true;
			},
			getShowRestore () {
				if (this.getContext !== 'admin') {
					return false;
				}
				if (this.course?.status !== 'deleted') {
					return false;
				}
				return true;
			},
			getGroups () {
				return [
					{
						heading: 'Notifications',
						rows: [
							(this.getShowDisableNotifications ?
								{
									infoText: 'Receiving course updates',
									action: {
										text: 'Turn off',
										actionId: 'disableNotifications'
									}
								} : false
							),
							(this.getShowEnableNotifications ?
								{
									infoText: 'Not receiving course updates',
									action: {
										text: 'Turn on',
										actionId: 'enableNotifications'
									}
								} : false
							)
						].filter(Boolean)
					},
					{
						heading: 'Publishing',
						rows: [
							(this.getShowLinkStripe ?
								{
									infoText: 'Payment details not set up',
									isImportant: true,
									action: {
										text: 'Set up',
										route: '/teaching/payment'
									}
								} : false
							),
							(this.getShowRequestPublication ?
								{
									infoText: 'Not approved for publication by an admin',
									action: {
										text: 'Request',
										actionId: 'requestPublication'
									}
								} : false
							),
							(this.getShowRequestedPublication ?
								{
									infoText: 'Approval for publication by an admin requested'
								} : false
							),
							(this.getShowApprovedForPublication ?
								{
									infoText: 'Approval for publication by an admin granted',
									action: {
										text: 'View note',
										actionId: 'toggleViewApprovalNote'
									}
								} : false
							),
							(this.getShowRejectedForPublication ?
								{
									infoText: 'Approval for publication by an admin not granted',
									action: {
										text: 'View note',
										actionId: 'toggleViewApprovalNote'
									}
								} : false
							),
							(this.getShowReview ?
								{
									infoText: 'Admin approval requested',
									isImportant: true,
									action: {
										text: 'Review course for publishing',
										actionId: 'toggleEditApprovalNote'
									}
								} : false
							),
							(this.getShowReviewedAndApproved ?
								{
									infoText: 'Admin approval granted',
									action: {
										text: 'View note',
										actionId: 'toggleViewApprovalNote'
									}
								} : false
							),
							(this.getShowReviewedAndRejected ?
								{
									infoText: 'Admin approval not granted',
									isImportant: true,
									action: {
										text: 'View note',
										actionId: 'toggleViewApprovalNote'
									}
								} : false
							),
							(this.getShowPublish ?
								{
									infoText: 'Not available to any students',
									action: {
										text: 'Publish',
										actionId: 'requestPublication'
									}
								} : false
							),
							(this.getShowUnpublish ?
								{
									infoText: 'Published and available to everyone',
									action: {
										text: 'Unpublish',
										actionId: 'toggleUnpublishCourseConfirmation'
									}
								} : false
							),
							(this.getShowRepublish ?
								{
									infoText: 'Not available to any students',
									action: {
										text: 'Republish',
										actionId: 'requestPublication'
									}
								} : false
							)
						].filter(Boolean)
					},
					{
						heading: 'Archiving',
						rows: [
							(this.getShowArchive ?
								{
									infoText: 'Available to new students',
									action: {
										text: 'Archive',
										actionId: 'toggleArchiveCourseConfirmation'
									}
								} : false
							),
							(this.getShowUnarchive ?
								{
									infoText: 'Only available to current students',
									action: {
										text: 'Unarchive',
										actionId: 'unarchiveCourse'
									}
								} : false
							)
						].filter(Boolean)
					},
					{
						heading: 'Deleting',
						rows: [
							(this.getShowDelete ?
								{
									infoText: 'Can only be undone by an admin',
									action: {
										text: 'Delete',
										actionId: 'toggleDeleteCourseConfirmation'
									}
								} : false
							),
							(this.getShowRestore ?
								{
									infoText: 'Has been deleted',
									action: {
										text: 'Restore',
										actionId: 'restoreCourse'
									}
								} : false
							)
						].filter(Boolean)
					}
				];
			}
		},
		methods: {
			async enableNotifications () {
				const notification = await api.courses.addNotificationsByCourseId({
					courseId: this.getCourseId
				});
				if (!notification) {
					return false;
				}
				this.course.notifications = true;
				this.$store.commit('ui/showNotification', {
					notification: 'You will be sent email notifications about lessons this course.'
				});
			},
			async disableNotifications () {
				const notification = await api.courses.removeNotificationsByCourseId({
					courseId: this.getCourseId
				});
				if (!notification) {
					return false;
				}
				this.course.notifications = false;
				this.$store.commit('ui/showNotification', {
					notification: 'You won’t receive any more email notifications about this course.'
				});
			},
			async requestPublication () {
				const course = await api[this.getApiRoot].requestPublicationByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.course.approval = course.approval;
				if (course.status !== 'published') {
					return this.$store.commit('ui/showNotification', {
						notification: 'The course has been sent for approval.'
					});
				}
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been published without needing approval.'
				});
			},
			toggleEditApprovalNote () {
				this.editApprovalNoteIsVisible = !this.editApprovalNoteIsVisible;
			},
			toggleViewApprovalNote () {
				this.viewApprovalNoteIsVisible = !this.viewApprovalNoteIsVisible;
			},
			toggleUnpublishCourseConfirmation () {
				this.unpublishCourseConfirmationIsVisible = !this.unpublishCourseConfirmationIsVisible;
			},
			toggleArchiveCourseConfirmation () {
				this.archiveCourseConfirmationIsVisible = !this.archiveCourseConfirmationIsVisible;
			},
			toggleDeleteCourseConfirmation () {
				this.deleteCourseConfirmationIsVisible = !this.deleteCourseConfirmationIsVisible;
			},
			async approveCourse () {
				const course = await api.admin.approvePublicationByCourseById({
					courseId: this.getCourseId,
					course: {
						approvalNote: this.course.approvalNote
					}
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.course.approval = course.approval;
				this.course.approvalNote = course.approvalNote;
				this.editApprovalNoteIsVisible = false;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been approved.'
				});
			},
			async rejectCourse () {
				const course = await api.admin.rejectPublicationByCourseById({
					courseId: this.getCourseId,
					course: {
						approvalNote: this.course.approvalNote
					}
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.course.approval = course.approval;
				this.course.approvalNote = course.approvalNote;
				this.editApprovalNoteIsVisible = false;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been rejected.'
				});
			},
			async unpublishCourse () {
				this.toggleUnpublishCourseConfirmation();
				const course = await api[this.getApiRoot].unpublishByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been unpublished.'
				});
			},
			async archiveCourse () {
				this.toggleArchiveCourseConfirmation();
				const course = await api[this.getApiRoot].archiveByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been archived.'
				});
			},
			async unarchiveCourse () {
				const course = await api[this.getApiRoot].unarchiveByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been unarchived.'
				});
			},
			async deleteCourse () {
				this.toggleDeleteCourseConfirmation();
				const course = await api[this.getApiRoot].deleteByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				if (this.entryRoute === 'teaching') {
					await this.$router.push(this.getBreadcrumbRoute);
				}
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been deleted.'
				});
			},
			async restoreCourse () {
				const course = await api.admin.unDeleteByCourseId({
					courseId: this.getCourseId
				});
				if (!course) {
					return false;
				}
				this.course.status = course.status;
				this.$store.commit('ui/showNotification', {
					notification: 'The course has been restored.'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	::v-deep [data-element='approval-note'] {
		font-size:rem(18);
		@include rich-text-content;
		@include rich-text-suits;
	}
	[data-component='course-action-groups'] {
	}
</style>
