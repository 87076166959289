<template>
	<div
		data-component="action-list"
		v-if="getActions"
	>
		<btn
			v-for="(btn, key) in getActions"
			:key="key"
			v-bind="btn"
			size="lg"
			@actionClick="onActionClick"
			:block="true"
			:isWaiting="isWaiting"
		/>
	</div>
</template>

<script>

	import Btn from '@/components/ui/Btn';

	export default {
		components: {
			Btn
		},
		props: {
			actions: {
				type: Array,
				default: () => ([])
			},
			isWaiting: {
				type: String,
				default: undefined
			}
		},
		computed: {
			getActions () {
				if (!this.actions?.length) {
					return false;
				}
				return this.actions;
			},
			getPrimaryActions () {
				if (!this.actions ||
						!this.actions.primary ||
						!this.actions.primary.length) {
					return false;
				}
				return this.actions.primary;
			},
			getSecondaryActions () {
				if (!this.actions ||
						!this.actions.secondary ||
						!this.actions.secondary.length) {
					return false;
				}
				return this.actions.secondary;
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='action-list'] {
		[data-component='btn'] {
			margin-bottom:rem(12);
			&:last-child {
				margin-bottom:0;
			}
		}
		[data-element='waiting'] {
			@include loader(3px);
		}
	}

</style>
