<template>
	<div
		data-component="additional-info-list"
		v-if="info.length"
	>
		<additional-info-list-row
			v-for="(row, key) in info"
			:key="key"
			:row="row"
		/>
	</div>
</template>

<script>

	import AdditionalInfoListRow from '@/components/ui/additionalInfoList/AdditionalInfoListRow';

	export default {
		components: {
			AdditionalInfoListRow
		},
		props: {
			info: {
				type: Array,
				default: undefined
			}
		},
		computed: {
		}
	};
</script>

<style lang="scss" scoped>
	[data-component='additional-info-list'] {
	}
</style>
