<template>
	<div data-component="lessons-modal">
		<page-header
			heading="Lessons"
			:action="getPageHeaderAction"
		/>
		<div data-element="main">
			<draggable-list
				v-if="lessons"
				:items="lessons"
				:courseId="getCourseId"
				:type="'lessons'"
				@reordered="onReordered"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader     from '@/components/ui/PageHeader';
	import DraggableList  from '@/components/ui/views/draggableList/DraggableList';
	import routeParams    from '@/mixins/routeParams';
	import api            from '@/services/api';

	export default {
		components: {
			PageHeader,
			DraggableList
		},
		mixins: [routeParams],
		data: () => ({
			lessons: []
		}),
		computed: {
			getPageHeaderAction () {
				if (!this.getCourseId) {
					return undefined;
				}
				if (this.getContext === 'admin') {
					return undefined;
				}
				return {
					text: 'New lesson',
					route: `/teaching/courses/${this.getCourseId}/lessons/create`
				};
			},
			getCourseId () {
				return parseInt(this.$route.params.courseId);
			}
		},
		created: function () {
			this.getLessons();
		},
		methods: {
			async onReordered (reorderedLessons) {
				const response = await api[this.getApiRoot].reorderLesssonsByCourseId({
					courseId: this.getCourseId,
					lessons: reorderedLessons.map((lesson) => {
						return lesson.id;
					})
				});
				if (!response) {
					return false;
				}
				this.$emit('updateLessonsOrder', reorderedLessons);
				this.$store.commit('ui/showNotification', {
					notification: 'New order has been saved.'
				});
			},
			async getLessons () {
				const response = await api[this.getApiRoot].getLessonsByCourseId({
					courseId: this.getCourseId
				});
				if (!response) {
					// this.$store.commit('ui/showError');
					return false;
				}
				this.lessons = response;
				// this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
