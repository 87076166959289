<template>
	<div
		data-component="draggable-list"
		:data-reordering="isReordering"
	>
		<div>
			<draggable
				v-model="getItems"
				v-bind="getDragOptions"
			>
				<draggable-list-item
					v-for="(item) in getItems"
					:item="item"
					:courseId="courseId"
					:lessonId="item.lessonId || item.id"
					:type="type"
					:key="item.id"
					:isReordering="isReordering"
				/>
			</draggable>
		</div>
		<actions
			:actions="getActions"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import DraggableListItem  from '@/components/ui/views/draggableList/DraggableListItem';
	import Actions            from '@/components/ui/Actions';
	import Draggable          from 'vuedraggable';

	export default {
		components: {
			DraggableListItem,
			Actions,
			Draggable
		},
		props: {
			items: {
				type: Array,
				default: () => []
			},
			courseId: {
				type: Number,
				default: undefined
			},
			type: {
				type: String,
				default: undefined
			}
		},
		data: () => ({
			isReordering: false,
			reorderedItems: []
		}),
		computed: {
			getDragOptions () {
				const options = {
					animation:    200,
					ghostClass:   'ghost',
					disabled:     true
				};
				if (this.isReordering) {
					options.disabled = false;
				}
				return options;
			},
			getActions () {
				return {
					secondary: [
						(!this.isReordering ?
							{
								text: 'Reorder ' + this.type,
								actionId: 'startReordering'
							} : false
						),
						(this.isReordering ?
							{
								text: 'Save order',
								actionId: 'stopReordering'
							} : false
						)
					].filter(Boolean)
				};
			},
			getItems: {
				get () {
					if (!this.reorderedItems?.length &&
							!this.items?.length) {
						return [];
					}
					if (!this.reorderedItems?.length) {
						return this.items;
					}
					return this.reorderedItems;
				},
				set (items) {
					this.reorderedItems = items;
				}
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!this[actionId] ||
						typeof this[actionId] !== 'function') {
					return;
				}
				this[actionId]();
			},
			startReordering () {
				this.isReordering = true;
			},
			stopReordering () {
				// Disables the reorder mode in list
				this.isReordering = false;
				this.$emit('reordered', this.getItems);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='draggable-list'] {}

</style>
