<template>
	<div data-component="add-student-modal">
		<validation-observer
			data-element="form"
			tag="form"
			ref="form"
			@submit.prevent="addStudentToCourse"
		>
			<select-field
				name="Student"
				:options="getStudents"
				placeholder="Type to search"
				:searchable="true"
				:internalSearch="false"
				v-model="idOfStudentToAdd"
				rules="required"
				@searchChange="searchStudents"
			/>
			<actions
				:actions="getActions"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
			/>
		</validation-observer>
	</div>
</template>

<script>

	import SelectField         from '@/components/forms/SelectField';
	import Actions             from '@/components/ui/Actions';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		components: {
			SelectField,
			Actions
		},
		mixins: [actionClick, routeParams, invalidFormMessage],
		data: () => ({
			asyncIsLoading: false,
			students: [],
			idOfStudentToAdd: undefined,
			isWaiting: undefined
		}),
		computed: {
			getStudents () {
				return this.students.map((student) => {
					return {
						text: `${student.firstName} ${student.lastName}`,
						value: student.id
					};
				});
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Add student',
							type: 'submit',
							btnId: 'submit'
						}
					]
				};
			}
		},
		created () {
			this.searchStudents();
		},
		methods: {
			async searchStudents (query) {
				this.asyncIsLoading = true;
				const students = await api.admin.getAllUsers({
					searchString: query,
					per: 200
				});
				if (!students) {
					this.asyncIsLoading = false;
					return;
				}
				this.students = students;
				this.asyncIsLoading = false;
			},
			async addStudentToCourse () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				this.isWaiting = 'submit';
				const response = await api.admin.enrolStudentInCourse({
					courseId: this.getCourseId,
					studentId: this.idOfStudentToAdd
				});
				if (!response) {
					this.isWaiting = undefined;
					return;
				}
				this.isWaiting = undefined;
				this.$store.commit('ui/showNotification', {
					notification: 'The student has been added'
				});
			}
		}
	};
</script>
