<template>
	<div data-component="lesson-list-item">
		<div data-element="details">
			<p data-element="title">
				{{ getLessonTitle }}
			</p>
			<p
				data-element="info"
				v-if="getInfoString"
			>
				<icon
					v-if="getCompletedIcon"
					icon="check"
					:colour="getInfoColour"
				/>
				<span
					:data-colour="getInfoColour"
				>
					{{ getInfoString }}
				</span>
			</p>
			<p data-element="description">
				{{ lesson.description }}
			</p>
		</div>
		<btn
			v-if="getLessonRoute"
			text="View lesson"
			:route="getLessonRoute"
			size="lg"
			colour="blue-lighter-1"
		/>
	</div>
</template>

<script>

	import Icon         from '@/components/ui/Icon';
	import Btn          from '@/components/ui/Btn';
	import routeParams  from '@/mixins/routeParams';

	export default {
		components: {
			Icon,
			Btn
		},
		mixins: [routeParams],
		props: {
			lesson: {
				type: Object,
				default: () => ({})
			},
			lessonNumber: {
				type: Number,
				default: undefined
			}
		},
		computed: {
			getLessonId () {
				return this.lesson?.id;
			},
			getLessonNumberString () {
				return `Lesson ${this.lessonNumber}`;
			},
			getLessonTitle () {
				if (!this.lesson?.title) {
					return false;
				}
				return `${this.getLessonNumberString} - ${this.lesson.title}`;
			},
			getActivityCount () {
				return this.lesson.activityCount;
			},
			getCompletedActivityCount () {
				if (this.getContext !== 'myCourses') {
					return false;
				}
				return this.lesson.activityProgress;
			},
			getActivityNoun () {
				if (this.getActivityCount === 1) {
					return 'activity';
				}
				return 'activities';
			},
			getSimpleActivityCountString () {
				const activityCount = this.getActivityCount;
				const noun = this.getActivityNoun;
				return `${activityCount} ${noun}`;
			},
			getCompletedActivityCountString () {
				const activityCount = this.getActivityCount;
				const completed = this.getCompletedActivityCount;
				const noun = this.getActivityNoun;
				return `${completed}/${activityCount} ${noun} completed`;
			},
			getActivitiesString () {
				if (this.getContext === 'myCourses' &&
						!this.lesson.live) {
					return this.getCompletedActivityCountString;
				}
				return this.getSimpleActivityCountString;
			},
			getScheduleString () {
				if (!this.lesson.live) {
					return false;
				}
				if (!this.lesson?.schedule?.startsAt) {
					return false;
				}
				const startsAt = this.lesson?.schedule?.startsAt;
				const startedAt = this.lesson?.schedule?.startedAt;
				const endedAt = this.lesson?.schedule?.endedAt;
				if (endedAt) {
					const time = this.$store.getters['auth/getTimeInLocalTimezone']({
						time: endedAt
					});
					return `Ended at ${time}`;
				}
				if (startedAt && !endedAt) {
					return 'Live now';
				}
				const time = this.$store.getters['auth/getTimeInLocalTimezone']({
					time: startsAt
				});
				return `Starts at ${time}`;
			},
			getCompletedIcon () {
				if (this.lesson.live) {
					if (!this.lesson?.schedule?.endedAt &&
							!this.lesson?.schedule?.startedAt) {
						return false;
					}
					if (!this.lesson?.schedule?.endedAt) {
						return 'exclamation';
					}
					return 'check';
				}
				if (this.getContext !== 'myCourses') {
					return false;
				}
				if (!this.lesson.isComplete) {
					return false;
				}
				return 'check';
			},
			getInfoColour () {
				if (!this.getCompletedIcon) {
					return false;
				}
				if (this.getCompletedIcon === 'exclamation') {
					return 'red';
				}
				return 'grey';
			},
			getInfoString () {
				if (this.lesson.live) {
					if (!this.getScheduleString) {
						return false;
					}
					return this.getScheduleString;
				}
				return this.getActivitiesString;
			},
			getLessonRoute () {
				if (this.getContext === 'myCourses' &&
						this.isLive) {
					return false;
				}
				if (this.getContext === 'browseCourses') {
					return false;
				}
				const contextSlug = this.getContextSlug;
				// TODO: Combine index and play routes for admin and teacher
				if (contextSlug === 'my-courses') {
					return `/${contextSlug}/${this.getCourseId}/lessons/${this.getLessonId}`;
				}
				return `/${contextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}`;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='lesson-list-item'] {
		display:flex;
		padding:rem(28) rem(24);
		[data-element='details'] {
			margin:auto rem(24) auto 0;
			[data-element='title'] {
				margin:0 0 rem(4);
				font-family:'Montserrat', sans-serif;
				font-size:rem(18);
				line-height:1.3;
				font-weight:700;
				color:$c-brand-blue-lighter-1;
			}
			[data-element='info'] {
				display:inline-flex;
				align-items:center;
				margin:0 0 rem(4);
				[data-component='icon'] {
					width:rem(22);
					height:auto;
					margin-right:rem(4);
				}
				span {
					font-size:rem(16);
					line-height:1.3;
					color:$c-brand-grey;
					&[data-colour='red'] {
						color:$c-brand-red;
					}
				}
			}
			[data-element='description'] {
				margin:0;
				font-size:rem(18);
				line-height:1.3;
			}
		}
		[data-component='btn'] {
			margin:auto 0 auto auto;
		}
	}

</style>
