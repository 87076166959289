<template>
	<div data-route>
		<div data-element="main">
			<course-view
				:course="course"
			/>
		</div>
	</div>
</template>

<route>
	{
		"meta": {
			"isAdminOnly": true
		}
	}
</route>

<script>

	import CourseView            from '@/components/courses/CourseView';
	import routeParams           from '@/mixins/routeParams';
	import api                   from '@/services/api';

	export default {
		metaInfo () {
			if (!this.getCourseTitle) {
				return false;
			}
			return {
				title: this.getCourseTitle
			};
		},
		components: {
			CourseView
		},
		mixins: [routeParams],
		data: () => ({
			course: {}
		}),
		computed: {
			getCourseTitle () {
				if (!this.course) {
					return false;
				}
				return this.course.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.getCourseTitle
					}
				];
			}
		},
		created () {
			this.setCourse();
		},
		methods: {
			async setCourse () {
				const course = await api.admin.getCourseById({
					courseId: this.getCourseId
				});
				if (!course) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.course = course;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
