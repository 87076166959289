<template>
	<div
		data-component="action-group"
		v-if="getRows"
	>
		<div
			v-if="getHeading"
			data-element="heading"
		>
			{{ getHeading }}
		</div>
		<div
			data-element="rows"
		>
			<action-group-row
				v-for="(row, key) in getRows"
				:key="key"
				:row="row"
				@actionClick="onActionClick"
			/>
		</div>
	</div>
</template>

<script>

	import ActionGroupRow from '@/components/ui/actionGroups/ActionGroupRow';

	export default {
		components: {
			ActionGroupRow
		},
		props: {
			group: {
				type: Object,
				default: undefined
			}
		},
		computed: {
			getRows () {
				if (!this.group?.rows?.length) {
					return false;
				}
				return this.group?.rows;
			},
			getHeading () {
				return this.group?.heading;
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='action-group'] {
		border:4px solid $c-brand-grey-lighter-5;
		border-radius:8px;
		[data-element='heading'] {
			margin:rem(22) rem(24);
			font-size:rem(22);
			font-weight:700;
			color:$c-brand-grey;
		}
		[data-element='rows'] {
			::v-deep [data-component='action-group-row'] {
				&:nth-of-type(2n + 1) {
					background-color:$c-brand-grey-lighter-7;
				}
			}
		}
	}

</style>
