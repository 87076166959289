<template>
	<div data-component="action-groups">
		<action-group
			v-for="(group, key) in groups"
			:key="key"
			:group="group"
			@actionClick="onActionClick"
		/>
	</div>
</template>

<script>

	import ActionGroup from '@/components/ui/actionGroups/ActionGroup';

	export default {
		components: {
			ActionGroup
		},
		props: {
			groups: {
				type: Array,
				default: () => ([])
			}
		},
		computed: {
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='action-groups'] {
		::v-deep [data-component='action-group'] {
			margin-bottom:rem(42);
			&:last-child {
				margin-bottom:0;
			}
		}
	}

</style>
