<template>
	<router-link
		data-component="draggable-list-item"
		:to="getRoute"
		:data-reordering="isReordering"
	>
		<div
			v-if="getItemType"
			data-element="type"
		>
			{{ getItemType }}
		</div>
		<div data-element="title">
			{{ getItemTitle }}
		</div>
	</router-link>
</template>

<script>

	import routeParams        from '@/mixins/routeParams';
	import { activityTypes }  from '@/consts';

	export default {
		mixins: [routeParams],
		props: {
			type: {
				type: String,
				default: undefined
			},
			lessonId: {
				type: Number,
				default: undefined
			},
			courseId: {
				type: Number,
				default: undefined
			},
			item: {
				type: Object,
				default: () => ({})
			},
			isReordering: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getItemTitle () {
				if (!this.item?.title) {
					return false;
				}
				return this.item.title;
			},
			getItemType () {
				if (!this.item?.type) {
					return false;
				}
				return activityTypes.find((activityType) => {
					return activityType.value === this.item.type;
				}).text;
			},
			getItemId () {
				if (!this.item?.id) {
					return false;
				}
				return this.item.id;
			},
			getCourseRoute () {
				if (!this.courseId) {
					return false;
				}
				return `/${this.getContextSlug}/courses/${this.courseId}`;
			},
			getLessonRoute () {
				if (!this.getCourseRoute ||
						!this.lessonId) {
					return false;
				}
				return `${this.getCourseRoute}/lessons/${this.lessonId}`;
			},
			getActivityRoute () {
				if (!this.getCourseRoute ||
						!this.getLessonRoute ||
						!this.getItemId) {
					return false;
				}
				return `${this.getLessonRoute}/activities/${this.getItemId}`;
			},
			getRoute () {
				if (this.type === 'activities') {
					return this.getActivityRoute;
				}
				return this.getLessonRoute;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='draggable-list-item'] {
		display:block;
		width:100%;
		padding:rem(10) 0;
		border-bottom: 1px solid $c-brand-grey-lighter-2;
		outline:0 none;
		background:transparent;
		cursor:pointer;
		user-select:none;
		[data-element='type'] {
			margin-bottom:rem(4);
			font-size:rem(16);
			line-height:1;
			color:$c-text;
			text-align:left;
		}
		[data-element='title'] {
			font-size:rem(22);
			line-height:1;
			color:$c-text;
			text-align:left;
			font-weight:700;
		}
		&[data-disabled] {
			cursor:default;
			color:$c-brand-grey-lighter-2;
		}
		&[data-reordering] {
			cursor:grab;
			&.ghost {
				background:$c-brand-grey-lighter-2;
			}
		}
	}

</style>
