<template>
	<div
		v-if="getLessons"
		data-component="lesson-list"
	>
		<h2 data-element="heading">
			Lessons
		</h2>
		<div data-element="lessons">
			<lesson-list-item
				v-for="(lesson, key) in lessons"
				:key="key"
				:lessonNumber="key+1"
				:lesson="lesson"
			/>
		</div>
	</div>
</template>

<script>

	import LessonListItem from '@/components/courses/LessonListItem';

	export default {
		components: {
			LessonListItem
		},
		props: {
			lessons: {
				type: Array,
				default: () => ([])
			}
		},
		computed: {
			getLessons () {
				if (!this?.lessons?.length) {
					return false;
				}
				return this.lessons;
			}
		},
		methods: {}
	};

</script>

<style lang="scss" scoped>
	[data-component='lesson-list'] {
		border:4px solid $c-brand-grey-lighter-5;
		border-radius:8px;
		[data-element='heading'] {
			margin:rem(22) rem(24);
			font-size:rem(22);
			font-weight:700;
			color:$c-brand-blue;
		}
		[data-element='lessons'] {
			::v-deep [data-component='lesson-list-item'] {
				&:nth-of-type(2n + 1) {
					background-color:$c-brand-grey-lighter-7;
				}
			}
		}
	}
</style>
